const Support = ({ ...props }) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_3454_3407)">
        <path
          d="M15.833 13.3333C17.674 13.3333 19.1663 11.9854 19.1663 10.3226C19.1663 9.0291 18.2633 7.92615 16.9956 7.5"
          stroke="#999999"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 13.3333C3.15904 13.3333 1.66667 11.9854 1.66667 10.3226C1.66667 9.0291 2.56971 7.92615 3.83742 7.5"
          stroke="#999999"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5 13.3332V13.1248V12.0832V9.99984V6.6665C5 3.90508 7.42513 1.6665 10.4167 1.6665C13.4082 1.6665 15.8333 3.90508 15.8333 6.6665V13.3332C15.8333 16.0946 13.4082 18.3332 10.4167 18.3332"
          stroke="#999999"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3454_3407">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default Support
